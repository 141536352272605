import PropTypes from 'prop-types'

import { createClient } from '../utils/prismic'
import { getComponentData, getPageData } from '../utils/getPageData'

import Hero from '../components/payoff-loan/Hero'
import Terms from '../components/payoff-loan/Terms'
import Ratings from '../components/payoff-loan/Ratings'
import Benefits from '../components/payoff-loan/Benefits'
import TheProcess from '../components/payoff-loan/TheProcess'
import Details from '../components/payoff-loan/Details'
import HelpCenter from '../components/payoff-loan/HelpCenter'
import FAQ from '../components/payoff-loan/FAQ'
import CTABlock from '../components/payoff-loan/CTABlock'
import Quotes from '../components/payoff-loan/Quotes'
import { interpolate } from '../utils/interpolate'
import { REVALIDATE_TIME_INTERVAL } from '../data/constants'

const PayoffLoan = (props) => {
  const { data } = props

  const helpCenter = getComponentData({
    data,
    key: 'mx',
  })

  return (
    <>
      <Hero
        data={getComponentData({
          data,
          key: 'hero',
        })}
      />
      <Terms
        data={getComponentData({
          data,
          key: 'terms',
        })}
      />
      <Ratings
        data={getComponentData({
          data,
          key: 'ratings',
        })}
      />
      <Benefits
        data={getComponentData({
          data,
          key: 'benefits',
        })}
      />
      <TheProcess
        data={getComponentData({
          data,
          key: 'process',
        })}
      />
      <Details
        data={getComponentData({
          data,
          key: 'payoff_details',
        })}
      />
      <Quotes
        data={getComponentData({
          data,
          key: 'quotes',
        })}
      />
      <HelpCenter
        data={{
          mxPhotos: helpCenter.mx,
          headline: helpCenter.mx_headline,
          description: helpCenter.mx_description,
          buttonHref: helpCenter.mx_button_href,
          buttonText: helpCenter.mx_button_text,
        }}
      />
      <FAQ
        data={getComponentData({
          data,
          key: 'faq',
        })}
      />
      <CTABlock
        data={getComponentData({
          data,
          key: 'cta',
        })}
      />
    </>
  )
}

export async function getStaticProps({ preview = null, previewData = {} }) {
  const client = createClient({ previewData })
  const { data: { template_variables: templateVarArr } } = await client.getSingle('template-variables')

  if (preview) {
    const { data } = await client.getByUID('payoff-loan', 'payoff-loan')
    const parsedData = await interpolate(templateVarArr, data)
    return {
      props: {
        preview,
        data: parsedData,
      },
    }
  }

  const data = await getPageData({ uid: 'payoff-loan' })
  const parsedData = await interpolate(templateVarArr, data)

  return {
    props: {
      data: parsedData,
    },
    revalidate: REVALIDATE_TIME_INTERVAL,
  }
}

PayoffLoan.defaultProps = {
  data: {},
}

PayoffLoan.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
}

export default PayoffLoan
