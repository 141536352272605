import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FadeUp } from '@happymoneyinc/move'

import Container from '../Container'
import BrandedQuote from '../BrandedQuote'
import Text from '../Text'
import Button from '../Button'
import IconList from '../IconList'

const Terms = (props) => {
  const {
    data: {
      terms_button_eyebrow: buttonEyebrow,
      terms_button_href: buttonHref,
      terms_button_text: buttonText,
      terms_quote_date: quoteDate,
      terms_quote_logo: quoteLogo,
      terms_quote_text: quoteText,
      terms_types: items,
    },
  } = props

  // rename keys in items for reusable component
  const iconItems = items.map(({ type, type_text, ...others }) => ({
    title: type,
    description: type_text,
    ...others
  }))

  return (
    <Wrapper center>
      <StyledFadeUp>
        <StyledIconList items={iconItems} />

        <StyledBrandedQuote
          quoteText={quoteText}
          quoteLogo={quoteLogo}
          quoteDate={quoteDate}
        />

        <Eyebrow size="buttonEyebrow" testID="terms-eyebrow">{buttonEyebrow}</Eyebrow>
        <Button buttonHref={buttonHref} color="azure" testID="terms-button">{buttonText}</Button>
      </StyledFadeUp>

    </Wrapper>
  )
}

const StyledBrandedQuote = styled(BrandedQuote)`
  margin-bottom: 100px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    margin-bottom: 120px;
  }
`
const Eyebrow = styled(Text)`
  margin: 0 0 32px;
`

const StyledIconList = styled(IconList)`
  margin-bottom: 100px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    margin-bottom: 120px;
  }
`

const Wrapper = styled(Container)`
  align-items: center;
  background-color: ${ (props) => props.theme.colors.chartreuse[3] };
`
const StyledFadeUp = styled(FadeUp)`
  align-items: center;
  flex-direction: column;
  display: flex;
`

Terms.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default Terms
