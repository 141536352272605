import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { RichText } from 'prismic-reactjs'

import Text from './Text'
import { textLargeStyles } from '../utils/cmsStyles'

const HeroList = (props) => {
  return (
    <List data-testid="hero-staticstic-list">
      {props.items.map((item, index) => (
        <ListItem key={`statistic-item-${ index.toString() }`} bgColor={item.bullet_color}>
          {
            typeof (item.statistic) === 'string' ?
              <Statistic testID={`statistic-${ index + 1 }`}>{ item.statistic }</Statistic> :
              (<RichTextDiv>{RichText.render(item.statistic)}</RichTextDiv>)
          }

        </ListItem>
      ))}
    </List>
  )
}

const List = styled.ul`
  padding-left: 20px;
  margin-top: 0;
  margin-bottom: 48px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.lg }) {
    margin-bottom: 72px;
  }
`

const ListItem = styled.li`
  position: relative;
  list-style-type: none;

  &::before {
    position: absolute;
    top: 3px;
    left: -20px;
    width: 8px;
    height: 8px;
    content: '';
    font-size: 24px;
    background: ${ ({ theme, bgColor }) => bgColor || theme.colors.chartreuse[3] };
    border: ${ ({ theme, bgColor }) => (bgColor ? `1px solid ${ theme.colors.black }` : 'none') };
    border-radius: 50%;

    @media screen and (min-width: ${ (props) => props.theme.breakpoints.xs1 }) {
      width: 10px;
      height: 10px;
    }
  }
`

const bulletPointSharedStyle = css`
  margin: 0;
  margin-bottom: 8px;
  line-height: 18px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.xs1 }) {
    margin-bottom: 18px;
  }

  a {
    text-decoration: underline !important;

    /* stylelint-disable-next-line a11y/selector-pseudo-class-focus */
    &:hover::after {
      opacity: 0;
    }
  }
`
const RichTextDiv = styled.div`
  ${ textLargeStyles }
  ${ bulletPointSharedStyle }

  .line-break {
    @media screen and (max-width: ${ (props) => props.theme.breakpoints.xs1 }) {
      font-size: 12px;
      white-space: nowrap;
    }
  }
`

const Statistic = styled(Text)`
  ${ bulletPointSharedStyle }
`

HeroList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    statistic: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  })).isRequired,
}

export default HeroList
