import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from 'next/image'

const HeroBadge = (props) => {
  const {
    badge,
    className,
  } = props

  return (
    <StyledPayOffBadge data-testid="hero-badge-image" className={className}>
      <Image
        alt={badge?.alt}
        height={badge?.dimensions?.height}
        src={badge?.url}
        width={badge?.dimensions?.width}
        layout="responsive"
        priority
        quality={100}
      />
    </StyledPayOffBadge>
  )
}

const StyledPayOffBadge = styled.p`
  width: 217px;
  height: 32px;
  margin-top: 0;
  margin-bottom: 32px;

  @media screen and (max-width: ${ (props) => props.theme.breakpoints.xs1 }) {
    width: 162px;
    height: 24px;
    margin-bottom: 24px;
  }
`

HeroBadge.defaultProps = {
  badge: {},
  className: '',
}

HeroBadge.propTypes = {
  className: PropTypes.string,
  badge: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string,
    dimensions: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number
    }),
  })
}

export default HeroBadge
