import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from 'next/image'

import { FadeUp } from '@happymoneyinc/move'

import Button from '../Button'
import Container from '../Container'
import Display from '../Display'
import Headline from '../Headline'
import Text from '../Text'

const Details = (props) => {
  const {
    data: {
      payoff_details_headline: chartHeadline,
      payoff_details_chart_items: chartItems,
      payoff_details_terms: termsItems,
      payoff_details_terms_button_eyebrow: button_eyebrow,
      payoff_details_terms_button_href: button_href,
      payoff_details_terms_button_text: button_text,
      payoff_details_terms_headline: termsHeadline,
    }
  } = props

  return (
    <Wrapper>
      <FadeUp>
        <Chart>
          <StyledHeader size="small" testID="chart-title" element="h2">{chartHeadline}</StyledHeader>
          <Table>
            <thead>
              <tr>
                <Th>&nbsp;</Th>
                <Th><StyledText size="eyebrow">The Payoff loan</StyledText></Th>
                <Th><StyledText size="eyebrow">credit cards</StyledText></Th>
              </tr>
            </thead>
            <tbody>
              {chartItems.map((item, index) => (
                <tr key={item.row_text}>
                  <Td><StyledHeadline size="medium" testID={`chart-row-text-${ index + 1 }`}>{item.row_text}</StyledHeadline></Td>
                  <TdIcon><IconWrapper><Image src="/chart/checkmark-circle.svg" width={48} height={48} layout="responsive" alt="checkmark" /></IconWrapper></TdIcon>
                  <TdIcon><IconWrapper><Image src="/chart/x-circle.svg" width={48} height={48} layout="responsive" alt="x mark" /></IconWrapper></TdIcon>
                </tr>
              ))}
            </tbody>
          </Table>
        </Chart>

        <Terms>
          <TermHeadline testID="details-terms-headline">{termsHeadline}</TermHeadline>
          <List>
            {termsItems.map((item, index) => (
              <ListItem index={index} key={item.term_headline}>
                <TermImageWrapper>
                  <Image alt={item.term_image.alt} src={item.term_image.url} height={item.term_image.dimensions.height} width={item.term_image.dimensions.width} />
                </TermImageWrapper>

                <TermTextWrapper>
                  <ItemHeadline size="medium" testID={`details-term-item-${ index + 1 }`}>{item.term_headline}</ItemHeadline>
                  <Text size="large" testID={`details-term-description-${ index + 1 }`}>{item.term_description}</Text>
                </TermTextWrapper>
              </ListItem>
            ))}
          </List>
        </Terms>

        <CTAWrapper>
          <Eyebrow size="buttonEyebrow" testID="details-button-eyebrow">{button_eyebrow}</Eyebrow>
          <Button buttonHref={button_href} buttonContent={button_text} color="white" testID="details-button" />
        </CTAWrapper>
      </FadeUp>
    </Wrapper>
  )
}

const Wrapper = styled(Container)`
  text-align: center;
  background-color: ${ ({ theme }) => theme.colors.cream };

  > div {
    padding-top: 40px;

    @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
      padding-top: 135px;
  }
  }
`
const StyledHeader = styled(Display)`
  @media screen and (width <= 610px) {
      width: 280px;
  }
`

const Chart = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  padding: 0;

  th:nth-child(2),
  td:nth-child(2) {
    background-color: ${ ({ theme }) => theme.colors.flamingo[3] };
  }
`

const Table = styled.table`
  width: fill-available;
  margin-top: 35px;
  box-shadow: 0 0 0 1px ${ ({ theme }) => theme.colors.black };
  border-collapse: collapse;
  border-style: hidden;
  border-radius: 10px;
`

const Th = styled.th`
  border-bottom: 1px solid ${ ({ theme }) => theme.colors.black };

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    padding: 5px;
    border: 1px solid ${ ({ theme }) => theme.colors.black };
  }

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    padding: 10px 32px;
  }

  :first-child {
    width: 58%;
  }
`

const Td = styled.td`
  padding: 0 18px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    border: 1px solid ${ ({ theme }) => theme.colors.black };
  }

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    padding: 20px 60px;
  }
`

const TdIcon = styled.td`
  padding: 10px 0;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    border: 1px solid ${ ({ theme }) => theme.colors.black };
  }

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.lg }) {
    padding: 30px 90px;
  }
`

const IconWrapper = styled.div`
  width: 32px;
  margin: 0 auto;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    width: 48px;
  }
`
const StyledText = styled(Text)`
  font-family: ${ ({ theme }) => theme.fonts.MabryProBold };
  text-transform: uppercase;

  @media screen and (max-width: ${ (props) => props.theme.breakpoints.sm }) {
    font-size: 12px;
  }
`

const StyledHeadline = styled(Headline)`
  text-align: left;
`

const Terms = styled.div`
  margin: 120px auto;
  margin-bottom: 80px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    margin: 180px 60px;
  }
`

const TermHeadline = styled(Headline)`
  max-width: 431px;
  margin: 0 auto;
  margin-bottom: 80px;
`

const List = styled.ul`
  display: grid;
  padding: 0;
  list-style: none;
  grid-row-gap: 90px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    grid-row-gap: 180px;
  }
`

const ListItem = styled.li`
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  width: 100%;
  max-width: 1000px;
  gap: 50px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    flex-direction:
    ${ ({ index }) => {
    if (index === 1) return 'row'
    return 'row-reverse'
  } };
  }
`

const TermImageWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0 25px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    padding: 0;
  }
`

const TermTextWrapper = styled.div`
  flex-basis: 39%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  text-align: left;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    max-width: 322px;
  }
`

const ItemHeadline = styled(Headline)`
  margin-bottom: 0;
`

const CTAWrapper = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
`
const Eyebrow = styled(Text)`
  width: 55%;
  margin-top: 0;
`

Details.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default Details
