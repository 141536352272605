import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from 'next/image'

import { FadeUp } from '@happymoneyinc/move'

import Container from '../Container'

const Ratings = (props) => {
  const {
    data: { ratings: items },
    bgColor,
    className
  } = props

  return (
    <Wrapper className={className} bgColor={bgColor}>
      <FadeUp>
        <Grid>
          {items.map((item, index) => (
            <ImageWrapper width={item.rating_image.dimensions.width} key={item.rating_image.url}>
              <Image layout="responsive" alt={item.rating_image.alt} src={item.rating_image.url} height={item.rating_image.dimensions.height} width={item.rating_image.dimensions.width} />
            </ImageWrapper>
          ))}
        </Grid>
      </FadeUp>
    </Wrapper>
  )
}

const Grid = styled.div`
  align-items: flex-end;
  display: grid;
  text-align: center;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 100px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.lg }) {
    column-gap: 110px;
  }

  > :nth-child(2) {
    @media screen and (max-width: ${ (props) => props.theme.breakpoints.sm }) {
      order: 1;
    }
  }
`

const ImageWrapper = styled.div`
  width: ${ ({ width }) => `${ width / 1.3 }px` };
  margin: 0 auto;

  @media (min-width: ${ (props) => props.theme.breakpoints.lg }) {
    width: ${ ({ width }) => `${ width }px` };
  }
`

const Wrapper = styled(Container)`
  background-color:
  ${ ({ bgColor, theme }) => {
    switch (bgColor) {
      case 'lemon':
        return theme.colors.lemon[3]
      default:
        return theme.colors.cream
    }
  } };
`

Ratings.defaultProps = {
  className: '',
  bgColor: 'cream'
}

Ratings.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  bgColor: PropTypes.string,
  className: PropTypes.string
}

export default Ratings
