import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from 'next/image'

import { FadeUp } from '@happymoneyinc/move'

import Container from '../Container'
import Display from '../Display'
import Headline from '../Headline'
import Text from '../Text'

const Benefits = (props) => {
  const {
    data: {
      benefits: items,
      benefits_headline: headline,
      benefits_subtext: subtext,
    }
  } = props

  return (
    <Wrapper center>
      <StyledFadeUp>
        <TitleWrapper>
          <StyledDisplay size="small" testID="benefits-title" element="h2">
            {headline}
          </StyledDisplay>
          <StyledHeadline size="medium" testID="benefits-subtext">
            {subtext}
          </StyledHeadline>
        </TitleWrapper>
        <Inner>
          {items.map((item, index) => (
            <Benefit key={item.benefit_headline}>
              <ImageWrapper>
                <Image alt={item.image.alt} src={item.image.url} width={240} height={240} />
              </ImageWrapper>
              <Headline size="medium" testID={`benefit-item-${ index + 1 }`}>
                {item.benefit_headline}
              </Headline>
              <BenefitText testID={`benefit-item-text-${ index + 1 }`}>
                {item.benefit_text}
              </BenefitText>
            </Benefit>
          ))}
        </Inner>
      </StyledFadeUp>
    </Wrapper>
  )
}

const StyledDisplay = styled(Display)`
  margin: 0;
`

const StyledHeadline = styled(Headline)`
  @media screen and (max-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    max-width: 306px;
    min-width: 251px;
    margin-right: auto;
    margin-left: auto;
    white-space: break-spaces;
  }
`

const StyledFadeUp = styled(FadeUp)`
  align-items: center;
  flex-direction: column;
  display: flex;
`

const TitleWrapper = styled.div`
  width: 90%;
  margin: 0;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    width: 50%;
  }
`

const ImageWrapper = styled.div`
  width: 160px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    width: 240px;
  }
`

const Benefit = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
`

const BenefitText = styled(Text)`
  max-width: 280px;
  margin: 0;
`

const Inner = styled.div`
  display: grid;
  margin-top: 80px;
  grid-gap: 120px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    margin-top: 100px;
    grid-column-gap: 127px;
    grid-template-columns: repeat(3, 1fr);
  }
`

const Wrapper = styled(Container)`
  text-align: center;
  background-color: ${ ({ theme }) => theme.colors.lemon[3] };
`

Benefits.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default Benefits
