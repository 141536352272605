import PropTypes from 'prop-types'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import DOMPurify from 'isomorphic-dompurify'

import { FadeUp } from '@happymoneyinc/move'

import Button from '../PageLinkButton'
import Container from '../Container'
import Display from '../Display'
import Headline from '../Headline'
import Text from '../Text'

const FAQ = (props) => {
  const {
    data: {
      faq: items,
      faq_button_eyebrow: button_eyebrow,
      faq_button_href: button_href,
      faq_button_text: button_text,
      faq_headline: headline,
    }
  } = props

  return (
    <Wrapper center>
      <FadeUp>
        <StyledDisplay size="small" testID="faq-title" element="h2">{headline}</StyledDisplay>
        <List>
          {items.map((item, index) => (
            <ListItem key={item.question}>
              <Question size="medium" testID={`faq-question-${ index + 1 }`}>{item.question}</Question>
              <Answer data-testid={`faq-answer-${ index + 1 }`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(RichText.asText(item.answer)) }} />
            </ListItem>

          ))}
        </List>

        <CTAWrapper>
          <Text size="buttonEyebrow" testID="faq-button-eyebrow">{button_eyebrow}</Text>
          <Button testID="faq-button" buttonHref={button_href} buttonContent={button_text} color="white" />
        </CTAWrapper>
      </FadeUp>
    </Wrapper>
  )
}

const Wrapper = styled(Container)`
  background-color: ${ ({ theme }) => theme.colors.flamingo[3] };
`

const StyledDisplay = styled(Display)`
  width: 75%;
  margin: 0 auto;
  text-align: center;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    width: 100%;
    margin: 0;
  }
`

const List = styled.ul`
  flex-direction: column;
  display: flex;
  width: 100%;
  padding: 0;
  margin-top: 60px;
  list-style: none;
  background-color: ${ ({ theme }) => theme.colors.white };
  border: 1px solid ${ ({ theme }) => theme.colors.black };
  border-radius: 5px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    margin-top: 90px;
  }
`

const ListItem = styled.li`
  flex-direction: column;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid ${ ({ theme }) => theme.colors.black };

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    padding: 72px 80px;
  }

  :last-child {
    border-bottom: none;
  }
`

const CTAWrapper = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  margin-top: 20px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    margin-top: 30px;
  }
`

const Question = styled(Headline)`
  margin: 0;
  margin-bottom: 12px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    margin-bottom: 24px;
  }
`

const Answer = styled.p`
  margin: 0;
  font-size: 15px;
  line-height: 160%;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    font-size: 16.5px;
  }

  a {
    color: ${ ({ theme }) => theme.colors.flamingoDarker };

    ::after {
      background-color: ${ ({ theme }) => theme.colors.flamingoDarker };
    }
  }
`

FAQ.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default FAQ
