import Image from 'next/image'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { FadeUp } from '@happymoneyinc/move'

import Button from '../PageLinkButton'
import Headline from '../Headline'
import Text from '../Text'

const HelpCenter = ({ data, version }) => {
  const {
    mxPhotos,
    buttonHref,
    buttonText,
    description,
    headline,
  } = data

  return (
    <Wrapper version={version}>
      <FadeUp>
        <LeftBox>
          <TextWrapper>
            <StyledHeadline size="large" testID="mx-title">{headline}</StyledHeadline>
            <StyledText size="large" testID="mx-description">{description}</StyledText>
            <Button testID="mx-button" color={version === 'v2' ? 'poppy' : 'white'} buttonContent={buttonText} buttonHref={buttonHref} />
          </TextWrapper>
        </LeftBox>

        <RightBox version={version}>
          {mxPhotos.map((item, index) => (
            <Image key={item.image?.url} layout="responsive" src={item.image?.url} width={item.image?.dimensions?.width} height={item.image?.dimensions?.height} alt={item.image?.alt} />
          ))}
        </RightBox>
      </FadeUp>
    </Wrapper>
  )
}

const StyledHeadline = styled(Headline)`
  margin: 0;
  margin-bottom: 24px;
`

const StyledText = styled(Text)`
  margin-bottom: 48px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    margin-bottom: 72px;
  }
`

const LeftBox = styled.div`
  align-items: center;
  flex-basis: 50%;
  display: flex;
  text-align: center;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    text-align: left;
    background-color: ${ ({ theme }) => theme.colors.cream };
  }
`

const TextWrapper = styled.div`
  margin: 100px 33px 40px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    max-width: 380px;
    margin: 0 auto;
  }

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.lg }) {
    margin-left: 140px;
  }
`

const RightBox = styled.div`
  flex-basis: 50%;
  display: grid;
  width: auto;
  padding: 40px 25px 80px 24px;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    align-self: center;
    padding: 40px;
    margin: 0 auto;
  }

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.lg }) {
    padding: 120px 95px;
  }

  ${ ({ version }) => version === 'v2' && css`
    padding: 0 !important;
    gap: 0;

    @media screen and (max-width: ${ (props) => props.theme.breakpoints.sm }) {
      order: -1;
    }
  ` }
`

const Wrapper = styled.section`
  flex-direction: column;
  display: flex;
  background-color: ${ ({ theme, version }) => (version === 'v2' ? theme.colors.cream : theme.colors.lemon[3]) };

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    flex-direction: row;
  }

  .detect-wrapper {
    width: 100%;

    > div {
      flex-direction: column;
      display: flex;

      @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
        flex-direction: row;
      }
    }
  }
`

HelpCenter.defaultProps = {
  version: 'v1'
}

HelpCenter.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  version: PropTypes.string
}

export default HelpCenter
