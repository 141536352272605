import Image from 'next/image'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FadeUp } from '@happymoneyinc/move'

import Display from '../Display'
import HeroCTA from '../HeroCTA'
import HeroBadge from '../HeroBadge'
import HeroList from '../HeroList'

const Hero = (props) => {
  const {
    data: {
      hero_button_href: button_href,
      hero_button_subtext: button_subtext,
      hero_button_text: button_text,
      hero_headline: headline,
      hero_image: image,
      hero_statistic: items,
      hero_badge: badge,
    }
  } = props

  return (
    <Wrapper>
      <Inner>
        <Box padding={{
          mobile: '32px 24px 0 24px',
          desktop: '80px 24px 0 80px'
        }}
        >
          <ContentWrapper>
            <HeroBadge badge={badge} />
            <StyledDisplay size="medium" testID="hero-title">{headline}</StyledDisplay>
            <HeroList items={items} />
            <HeroCTA
              buttonContent={button_text}
              buttonHref={button_href}
              buttonColor="white"
              buttonTip={button_subtext}
              inviteCodeHref="/forward"
            />
          </ContentWrapper>
        </Box>
        <Box>
          <Fade>
            <Image
              alt={image?.alt}
              height={image?.dimensions?.height}
              src={image?.url}
              width={image?.dimensions?.width}
              layout="responsive"
              priority
              quality={100}
            />
          </Fade>
        </Box>
      </Inner>
    </Wrapper>
  )
}

const StyledDisplay = styled(Display)`
  margin-top: 0;
  margin-bottom: 24px;
  text-align: left;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.xs1 }) {
    font-size: ${ ({ theme }) => `${ theme.display.medium.fontSizeMin }px` };
  }

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.lg }) {
    max-width: 471px;
    margin-bottom: 32px;
    font-size: ${ ({ theme }) => `${ theme.display.medium.fontSizeMax }px` };
  }
`

const Inner = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    flex-direction: row;
  }
`

const Box = styled.div`
  position: relative;
  flex-basis: 100%;
  max-height: 848px;
  padding: ${ ({ padding }) => padding?.mobile };

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    flex-basis: 50%;
    padding: ${ ({ padding }) => padding?.desktop };
  }
`

const ContentWrapper = styled.div`
  box-sizing: content-box;
  padding-bottom: 48px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    padding-bottom: 0;
  }

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.xl }) {
    max-width: 600px;
    margin: 0 auto;
  }
`

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${ ({ theme }) => theme.colors.flamingo[3] };
`

const Fade = styled(FadeUp)`
  transform: translate3d(0, 0, 0);
`

Hero.propTypes = {
  data: PropTypes.shape({
    hero_button_href: PropTypes.string,
    hero_button_subtext: PropTypes.string,
    hero_button_text: PropTypes.string,
    hero_headline: PropTypes.string,
    hero_image: PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string,
      dimensions: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number
      })
    }),
    hero_statistic: PropTypes.arrayOf(PropTypes.shape({
      statistic: PropTypes.string,
    })),
    hero_badge: PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string,
      dimensions: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number
      })
    }),
  }).isRequired,
}

export default Hero
