import Link from 'next/link'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ButtonBase } from './Button'
import { checkExternalUrl } from '../utils/checkExternalUrl'

const PageLinkButton = (props) => {
  const { className, buttonContent, buttonHref, testID, mobileColor, color } = props
  const isExternal = checkExternalUrl(buttonHref)

  if (buttonHref) {
    return (
      <Link href={buttonHref} passHref>
        <Anchor
          target={isExternal ? '_blank' : null}
          rel={isExternal ? 'noreferrer' : null}
          className={className}
          data-testid={testID}
          href={buttonHref}
          color={color}
          mobileColor={mobileColor}
        >
          {buttonContent}
        </Anchor>
      </Link>
    )
  }
}

const Anchor = styled(ButtonBase)`
  &::after {
    content: none;
  }
`

PageLinkButton.defaultProps = {
  className: '',
  buttonContent: '',
  buttonHref: null,
  testID: null,
  color: 'flamingo',
  mobileColor: null,
}

PageLinkButton.propTypes = {
  className: PropTypes.string,
  buttonContent: PropTypes.node,
  buttonHref: PropTypes.string,
  testID: PropTypes.string,
  color: PropTypes.string,
  mobileColor: PropTypes.string,
}

export default PageLinkButton
