import PropTypes from 'prop-types'
import styled from 'styled-components'
import Image from 'next/image'

import { FadeUp } from '@happymoneyinc/move'

import Button from '../Button'
import Container from '../Container'
import Display from '../Display'
import Headline from '../Headline'
import Text from '../Text'

const TheProcess = (props) => {
  const {
    data: {
      process_button_eyebrow: button_eyebrow,
      process_button_href: button_href,
      process_button_text: button_text,
      process_headline: headline,
      processes: items,
    }
  } = props

  return (
    <Wrapper>
      <FadeUp>
        <StyledDisplay size="small" testID="process-title" element="h2">{headline}</StyledDisplay>

        <List>
          {items.map((item, index) => {
            const mobileImage = item.image.mobile || item.image
            return (
              <ListItem index={index} key={item.image.url}>
                <ListItemInner index={index} key={item.process_headline}>
                  <ProcessImageWrapper>
                    <Inner>
                      <DesktopImage>
                        <Image layout="responsive" alt={item.image.alt} src={item.image.url} height={item.image.dimensions.height} width={item.image.dimensions.width} />
                      </DesktopImage>
                      <MobileImage>
                        <Image layout="responsive" alt={mobileImage.alt} src={mobileImage.url} height={mobileImage.dimensions.height} width={mobileImage.dimensions.width} />
                      </MobileImage>
                    </Inner>
                  </ProcessImageWrapper>
                  <ProcessTextWrapper backgroundColor={item.background_color}>
                    <Number>{`((${ index + 1 }))`}</Number>
                    <Name size="medium" data-testid={`process-item-headline-${ index + 1 }`}>{item.process_repeatable_headline}</Name>
                    <StyledText testID={`process-item-text-${ index + 1 }`}>{item.process_repeatable_text}</StyledText>
                  </ProcessTextWrapper>
                </ListItemInner>
              </ListItem>
            )
          })}
        </List>

        <CTAWrapper>
          <Eyebrow testID="process-button-eyebrow">{button_eyebrow}</Eyebrow>
          <Button buttonHref={button_href} buttonContent={button_text} color="white" testID="process-button" />
        </CTAWrapper>
      </FadeUp>
    </Wrapper>
  )
}

const ListItemInner = styled.div`
  flex-direction: column;
  display: flex;
  border: 1px solid ${ ({ theme }) => theme.colors.black };
  border-radius: 5px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    flex-direction: row;
    height: 240px;
    text-align: left;
  }
`

const CTAWrapper = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  margin-top: 100px;
  text-align: center;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    margin-top: 120px;
  }
`

const Eyebrow = styled(Headline)`
  width: 75%;
  margin-top: 0;
`

const StyledDisplay = styled(Display)`
  width: 70%;
  margin: 0 auto;
  margin-bottom: 45px;
  text-align: center;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    width: 40%;
    margin: initial;
    margin-bottom: 80px;
    text-align: left;
  }
`

const StyledText = styled(Text)`
  margin: 0;
`

const Name = styled(Headline)`
  margin: 0 0 8px;
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProBold }` };
`

const Number = styled.p`
  margin: 0 0 12px;
  font-feature-settings: 'ss10' on;
  font-size: 40px;
`

const ListItem = styled.li`
  width: 100%;
  max-width: 664px;
  text-align: center;
  justify-self:
 ${ ({ index }) => {
    if (index === 0) return 'start'
    if (index === 1) return 'center'
    return 'end'
  } };
`

const ProcessImageWrapper = styled.div`
  align-items: center;
  flex-basis: 36%;
  justify-content: center;
  display: flex;
  padding-top: 40px;
  background-color: ${ ({ theme }) => theme.colors.white };
  border-radius: 5px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    padding-top: 0;
    border-right: 1px solid ${ ({ theme }) => theme.colors.black };
    border-radius: 5px 0 0 5px;
  }
`

const Inner = styled.div`
  width: 100px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    width: 150px;
  }
`

const DesktopImage = styled.div`
  position: relative;
  display: none;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    display: block;
  }
`

const MobileImage = styled.div`
  position: relative;
  display: block;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    display: none;
  }
`

const ProcessTextWrapper = styled.div`
  flex-basis: 64%;
  flex-direction: column;
  justify-content: center;
  display: flex;

  min-height: 270px;
  padding: 0 40px;
  background-color: ${ ({ theme }) => theme.colors.white };
  border-radius: 5px;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    min-height: auto;
    padding: 0 32px;
    background-color: ${ ({ backgroundColor }) => backgroundColor };
  }
`

const List = styled.ul`
  display: grid;
  padding: 0;
  margin: 0;
  list-style: none;
  grid-row-gap: 48px;
`

const Wrapper = styled(Container)`
  background-color: ${ ({ theme }) => theme.colors.cream };
`

TheProcess.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default TheProcess
